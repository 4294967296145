<template>
	<router-link
		tag="div"
		:to="{ path: business.url }"
		class="w-full py-3 text-left bg-white border-t border-gray-300 cursor-pointer"
	>
		<div class="flex w-full px-3 bg-white observe">
			<WwImage
				:src="businessImageFile"
				:fallback-image="DEFAULT_WW_GRAY_LOGO"
				classes="rounded-lg max-w-22 md:max-w-26 mr-3"
				:lazy="lazyLoadImage"
				:width="imageDimension"
				:height="imageDimension"
				:image-path="LISTING_IMAGES"
				:alt="businessName + 'Thumbnail Image'"
				:fetch-priority="fetchPriority"
			/>

			<div class="justify-center">
				<div class="grid h-full grid-cols-1">
					<span
						class="mt-auto mb-1 font-bold leading-tight text-black line-clamp-2"
					>
						{{ businessName }}
					</span>
					<div
						class="flex items-center mb-1 text-sm"
						:class="hasRating ? 'text-black' : 'text-gray-500'"
					>
						<img
							:src="starIcon"
							:width="isMobile ? 16 : 18"
							:height="isMobile ? 16 : 18"
							class="mr-1"
							alt="Star Rating"
							tabindex="0"
						>
						<span
							class="font-bold after:content-['\22C5'] after:text-gray-300 after:px-2"
							tabindex="0"
						>
							{{ formattedAverage }}
							<template
								v-if="hasReviews"
							>
								&nbsp;({{ business.review_count }})
							</template>
						</span>

						<template v-if="!isDC">
							{{ business.city }}, {{ business.state_short }}
						</template>
						<template v-else>
							{{ business.city }}
						</template>
					</div>

					<div class="flex items-center mb-1 text-xs text-gray-500 md:text-sm capitalize">
						<img
							:src="businessIcon"
							class="mr-2"
							:width="isMobile ? '16' : '18'"
							:height="isMobile ? '16' : '18'"
							tabindex="0"
							alt="Business Icon"
						>
						{{ business.type }}

						<span class="mx-2 text-gray-300">
							•
						</span>
						{{ businessIndication }}
					</div>

					<div
						v-if="hasChips"
						class="mt-auto text-xs font-bold uppercase md:text-sm"
					>
						<span
							v-if="hasHours && isOpen"
							class="text-green-500 bg-green-100 px-2 py-0.5 mr-2 rounded"
							tabindex="0"
						>
							{{ statusText }}
						</span>
						<span
							v-if="hasDeals"
							class="text-yellow-500 bg-yellow-100 px-2 py-0.5 mr-2 rounded"
							tabindex="0"
						>
							{{ dealCount }} Deals
						</span>
						<span
							v-if="hasDelivery"
							class="text-black bg-gray-200 px-2 py-0.5 rounded"
							tabindex="0"
						>
							Delivery
						</span>
					</div>

					<div
						v-else
						class="mt-auto py-2.5"
					/>
				</div>
			</div>
		</div>
		<div
			v-if="hasOrdering"
			class="flex w-11/12 mx-auto px-3 pt-2 mt-2 items-center justify-center py-2 text-xs font-bold text-center uppercase bg-white border-2 border-gray-300 border-solid rounded-lg shadow-sm"
		>
			<img
				:src="cartIcon"
				class="mr-2"
				width="16"
				height="16"
				alt="Cart Icon"
			>
			View Online Menu
		</div>
	</router-link>
</template>

<script async>
import { mapGetters } from 'vuex'

import WwImage from '@/components/multiUse/WwImage.vue'
import { BUSINESS_TYPE_ICONS } from '@/constants/business/index.js'
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import requireIcon from '@/utils/requireIcon.js'

export default {
	components: {
		WwImage
	},
	props: {
		business: {
			type: Object,
			required: true
		},
		lazyLoadImage: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			LISTING_IMAGES,
			DEFAULT_WW_GRAY_LOGO,
			imageDimension: 100
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		businessName() {
			return this.business.name || '-'
		},
		businessIndication() {
			switch (this.business?.indication) {
				case 'medical':
					return 'Medical Only'
				case 'recreational':
					return 'Recreational Only'
				default:
					return 'Medical & Recreational'
			}
		},
		businessImageFile() {
			return this.business?.image ? this.business.image : false
		},
		businessIcon() {
			return requireIcon(BUSINESS_TYPE_ICONS[this.business.type])
		},
		cartIcon() {
			return requireIcon('cart2.svg')
		},
		hasHours() {
			return !!this.business?.hours?.length
		},
		statusText() {
			return this.isOpen ? 'Open' : 'Closed'
		},
		isOpen() {
			return !!this.business?.is_open
		},
		isDC() {
			return (this.business.state_name && this.business.state_name.includes('DC')) || (this.business.state_short && this.business.state_short.includes('DC'))
		},
		reviewAverage() {
			return (Math.round(this.business?.review_average * 10) / 10).toFixed(1)
		},
		formattedAverage() {
			return this.reviewAverage % 1 == 0 ? parseInt(this.reviewAverage) : this.reviewAverage
		},
		hasChips() {
			return this.isOpen || this.hasDeals || this.hasDelivery
		},
		hasOrdering() {
			return this.business?.has_ordering
		},
		hasRating() {
			return this.reviewAverage !== 0
		},
		hasDeals() {
			return this.dealCount !== undefined && this.dealCount > 0
		},
		hasDelivery() {
			return this.business?.delivery == 1
		},
		hasReviews() {
			return this.business?.review_count
		},
		dealCount() {
			return this.business?.deals_count
		},
		starIcon() {
			return this.hasRating ? requireIcon('star-full-yellow.svg') : requireIcon('star-full-black.svg')
		},
		fetchPriority() {
			return this.lazyLoadImage ? 'auto' : 'high'
		}
	}
}
</script>
