import { PACKAGE_IDS } from '@/constants/business/business-packages.js'

import {
	CLAIMED_ICON_SIZE,
	FEATURED_ICON_SIZE,
	PREMIUM_ICON_SIZE,
	UNCLAIMED_ICON_SIZE,
	VERIFIED_ICON_SIZE
} from '../constants/map/map-icon-sizes.js'

let resizedMarker = null

export const deleteResizedMarker = () => {
	if (resizedMarker) {
		resizedMarker.setMap(null)
	}
	resizedMarker = null
}

export const resizeSelectedMarker = ({
	map, marker, percentageToResize
}) => {
	const {
		customInfo, icon, businessId, zIndex
	} = marker
	let dimensions = []
	switch (true) {
		case customInfo.featured === PACKAGE_IDS.PREMIUM_ID:
			dimensions = PREMIUM_ICON_SIZE
			break
		case customInfo.featured === PACKAGE_IDS.FEATURED_ID:
			dimensions = FEATURED_ICON_SIZE
			break
		case customInfo.verified === PACKAGE_IDS.VERIFIED_ID:
			dimensions = VERIFIED_ICON_SIZE
			break
		case customInfo.claimed:
			dimensions = CLAIMED_ICON_SIZE
			break
		default:
			dimensions = UNCLAIMED_ICON_SIZE
			break
	}

	const resizedX = dimensions[0] * ((percentageToResize / 100) + 1)
	const resizedY = dimensions[1] * ((percentageToResize / 100) + 1)

	const image = {
		url: icon,
		scaledSize: new window.google.maps.Size(resizedX, resizedY),
		origin: new window.google.maps.Point(0, 0),
		anchor: new window.google.maps.Point(resizedX / 2, resizedY * 0.95)

	}

	const shape = {
		coords: [ dimensions[0], dimensions[1], dimensions[0] / 2 ],
		type: 'circle'
	}

	const currentMarker = new window.google.maps.Marker({
		position: { lat: customInfo.lat, lng: customInfo.lon },
		map,
		icon: image,
		shape: shape,
		title: businessId,
		zIndex: zIndex + 5000
	})

	if (resizedMarker) {
		deleteResizedMarker()
	}

	resizedMarker = currentMarker
}
