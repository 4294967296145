<template>
	<div class="text-left">
		<h1 class="max-w-6xl p-3 m-auto text-3xl">
			Marijuana Deals in {{ location.name }}
		</h1>
		<div class="max-w-6xl px-3 m-auto">
			<div class="flex flex-wrap">
				<div class="w-full mb-2 md:w-1/6">
					<div class="flex flex-wrap">
						<div class="w-full">
							<div
								v-if="isMobile && isMounted"
								class="flex justify-end w-full py-3 border-b border-gray-300"
							>
								<button
									class="flex items-center px-2 py-1 border border-gray-300 rounded shadow-sm"
									@click="showModal(DEAL_FILTER_MODAL)"
								>
									<img
										src="@/assets/icons/settings2.svg"
										height="15"
										width="15"
										class="mx-1"
										alt="Filters"
									>
									Filters
								</button>
							</div>
						</div>
						<div class="w-1/4 md:w-full">
							<WwModal
								:modal-id="DEAL_FILTER_MODAL"
								modal-position="items-start"
								title="Filters"
								max-width="max-w-md"
								hide-title="CLEAR"
								ok-color="green"
								ok-title="APPLY FILTERS"
								:hide-closes-modal="false"
								@hide="clearFilters"
								@ok="closeModal"
							>
								<template #body>
									<div
										v-for="(category, name, index) in filters"
										:key="index"
										class="flex flex-wrap py-2"
									>
										<h6 class="w-full py-2 mb-2 font-semibold text-center border border-gray-300 rounded-xl">
											{{ name.charAt(0).toUpperCase() + name.slice(1) }}
										</h6>
										<div class="flex flex-wrap items-center w-full justify-evenly">
											<div
												v-for="(filter, index) in category"
												:key="index"
												class="flex items-center"
											>
												<input
													:id="name + '-checkbox-' + index"
													v-model="checkbox[filter.name]"
													type="checkbox"
													class="m-2 text-green-500 border border-gray-300 rounded-sm shadow-sm focus:ring focus:ring-green-300 hover:shadow focus:border-gray-300 hover:bg-gray-200"
													:name="name + '-checkbox-' + index"
													:true-value="filter.id"
													unchecked-value=""
												>
												<label :for="name + '-checkbox-' + index">
													{{ filter.name }}
												</label>
											</div>
										</div>
									</div>
								</template>
							</WwModal>

							<div v-if="!isMobile && isMounted">
								<div
									v-for="(category, name, index) in filters"
									:key="index"
									class="py-2"
								>
									<div class="text-xl font-bold">
										{{ name.charAt(0).toUpperCase() + name.slice(1) }}
									</div>
									<div
										v-for="(filter, index) in category"
										:key="index"
										class="flex items-center my-1.5 ml-2 space-x-2"
									>
										<input
											:id="name + '-checkbox-' + index"
											v-model="checkbox[filter.name]"
											type="checkbox"
											class="text-green-500 border border-gray-300 rounded-sm shadow-sm focus:ring focus:ring-green-300 hover:shadow focus:border-gray-300 hover:bg-gray-200"
											:name="name + '-checkbox-' + index"
											:true-value="filter.id"
											unchecked-value=""
										>
										<label :for="name + '-checkbox-' + index">
											{{ filter.name }}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="w-full py-4 md:w-5/6 md:px-12 xl:pl-12 xl:pr-0">
					<!-- Start Product List -->
					<div class="flex flex-wrap">
						<div class="w-full h-full">
							<div v-if="cityDeals.length">
								<!-- #TODO: Add loading spinner, refactor deal card use -->
								<div class="grid grid-cols-1 gap-6 my-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
									<DealCard
										v-for="(deal, index) in cityDeals"
										:id="'deal-' + index"
										:key="index"
										:deal="deal"
										class="w-full"
										:prefetch-image="index < numberOfImagesToPrefetch"
									/>
								</div>
								<WwPagination
									:current-page="page"
									:page-count="dealsPageCount"
									class="w-full text-center"
									@page-change="handlePageChange"
								/>
							</div>
							<div
								v-else
								class="h-full"
							>
								<Transition
									appear
									name="fade"
								>
									<p class="text-center">
										There are no deals in your area.
									</p>
								</Transition>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>

import { mapActions, mapGetters, mapMutations } from 'vuex'

import DealCard from '@/components/deal/DealCard.vue'
import WwModal from '@/components/UI/WwModal.vue'
import WwPagination from '@/components/UI/WwPagination.vue'
import { DEAL_FILTER_MODAL } from '@/constants/modal/names.js'
import filters from '@/etc/generated/dealFilters.json'
import requireIcon from '@/utils/requireIcon.js'

const PER_PAGE = 25

export default {
	components: {
		DealCard,
		WwPagination,
		WwModal
	},
	data: function() {
		return {
			filters: filters,
			checkbox: {},
			page: 1,
			stopScroll: false,
			resultsCache: {},
			activeResult: -1,
			dontSearch: false,
			showAutocompleter: false,
			isLoading: false,
			locationOnly: true,
			placeholderAlt: 'City',
			placeholder: '',
			visible: {},
			DEAL_FILTER_MODAL,
			isMounted: false,
			numberOfImagesToPrefetch: 3
		}
	},
	computed: {
		...mapGetters('city', [
			'cityDeals',
			'dealsPageCount'
		]),
		...mapGetters([
			'location', 'isMobile'
		]),
		dealOffset() {
			return (this.page - 1) * PER_PAGE || 0
		},
		locationName() {
			return this.hasLocationData ? this.location.name : ''
		},
		hasLocationData() {
			return this.location && this.location?.city && this.location?.city?.name
		},
		comboFilter() {
			const combo = []

			for (const box in this.checkbox) {
				const name = this.checkbox[box]
				if (name !== '') {
					combo.push(name)
				}
			}
			return combo
		},
		dealsUrl() {
			return '/marijuana-dispensary-deals'
		},
		baseUrl() {
			return this.location?.url + this.dealsUrl
		},
		routeToCity() {
			return this.location?.url !== '/' + this.$route.params.city
		},
		hasFilters() {
			return this.$route.name === 'cityDealsWithFilters'
		},
		pageFromUrl() {
			const reg = /\/|\+/g
			const filters = this.$route.fullPath && this.$route.fullPath.substring(1).replace(reg, ',').split(',') // this will replace '+' and '/' with commas and remove leading slash
			const pageNumber = parseInt(filters[filters.length - 1])

			if (isNaN(pageNumber)) {
				return undefined
			} else {
				return pageNumber
			}
		},
		currentUrlFilterString() {
			// -Bo [3/10/21]
			let routeUrl = this.$route.params.city + this.dealsUrl + '/'

			if (this.$route.params.page !== undefined && !this.hasFilters) { // remove page from route WITHOUT filters
				routeUrl = routeUrl + this.$route.params.page
			}

			let currentRoute = this.$route.path.substring(1).replace(routeUrl, '') // substring to remove leading slash

			if (this.hasFilters) { // remove page from route WITH filters.
				if (this.pageFromUrl !== undefined) {
					currentRoute = currentRoute.slice(0, -2)
				}
			}

			if (this.baseUrl.substring(1) == currentRoute) { // this prevents duplicate routing error when hitting back to '/marijuana-dispensary-deals' (no trailing slash) from /marijuana-dispensary-deals/medical (with trailing slash)
				return ''
			} else {
				return currentRoute
			}
		},
		newUrlFilterString() {
			const tempArray = []
			let stringArray = []
			let filterStrings = {}

			// generate our filterStrings object with an empty string for each filter category
			for (const category in this.filters) {
				filterStrings = Object.assign({}, filterStrings, { [category]: '' })
			}

			// populate tempArray to build url from it
			if (this.comboFilter.length > 0 && this.comboFilter !== undefined) { // add strain type(s) to filter if selected
				// get category names
				const keys = Object.keys(this.filters)

				for (let i = 0; i < keys.length; i++) { // loop through each category
					for (let n = 0; n < this.comboFilter.length; n++) { // loop through each filter that we have selected
						const filter = this.comboFilter[n] // set filter for easy usage
						const category = this.filters[keys[i]] // grab category
						for (let b = 0; b < category.length; b++) { // loop through each category
							if (category[b].id.includes(filter) && !stringArray.includes(filter)) { // make sure the category has the filter and that the filter is not already in our temporary string array
								// add filter to temporary array
								stringArray.push(filter)
							}
						}
					}
					// now give each filterString a string value by combining filters from our temporary stringArray
					filterStrings[keys[i]] = stringArray.join('+')
					// now add each combined and formatted string to the tempArray
					tempArray[i] = filterStrings[keys[i]]
					// clear the temporary stringArray to use again on next loop
					stringArray = []
				}
			}

			if (tempArray.length > 0) {
				return tempArray.filter(Boolean).join('/') // filter Boolean will filter out blank items
			} else {
				return ''
			}
		}
	},
	watch: {
		comboFilter() {
			this.setFilter()
		},
		currentUrlFilterString() {
			this.setInitialFilters()
		},
		'$route.params.page': {
			handler() {
				if (this.$route.params.page !== undefined) {
					this.page = this.$route.params.page
				} else if (this.pageFromUrl !== undefined) {
					this.page = this.pageFromUrl
				} else {
					this.page = 1
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.setInitialFilters()
		this.showCollapse()
		this.isMounted = true
	},
	methods: {
		...mapMutations('modal', [ 'showModal', 'closeModal' ]),
		...mapActions('city',
			[ 'NEXT_PAGE', 'SET' ]
		),
		...mapMutations('toast', [ 'showToast' ]),
		setVisibility(name) { // controls the collapse sidebar
			this.visible[name] = !this.visible[name]
		},
		showCollapse() { // generate the object properties to control the sidebar menu, set to true to start in open state on page load
			for (const name in filters) {
				this.visible = Object.assign({}, this.visible, { [name]: true })
			}
		},
		setInitialFilters() { // set filters on page load if they are in the url
			// now we can fill in checkboxes that were in the url
			const filterString = this.currentUrlFilterString // grab our url filter params

			// first we have to generate the object properties since v-model doesn't
			for (const categoryName in this.filters) { // loop thru filters obj for each category
				const category = this.filters[categoryName] // grab category
				for (const index in category) { // loop thru filters
					const filter = category[index] // grab filter
					this.checkbox = Object.assign({}, this.checkbox, { [filter.name]: '' }) // assign each property an empty string value
					if (filterString.includes(filter.id)) { // if the filterString has this filter, then set the corresponding checkbox value
						this.checkbox[filter.name] = filter.id // set checkbox
					}
				}
			}
		},
		clearFilters() {
			for (const box in this.checkbox) {
				this.checkbox[box] = ''
			}
		},
		setFilter() {
			if (this.currentUrlFilterString !== this.newUrlFilterString && !this.routeToCity) {
				this.page = 1
				this.$router.push({ path: this.baseUrl + '/' + this.newUrlFilterString, params: { page: this.page } })
			}
		},
		handlePageChange(changeAmount) {
			this.page = Number(this.page) + changeAmount

			if (!this.currentUrlFilterString.length) {
				this.$router.push({
					name: this.$route.name,
					params: { page: this.page }
				})
			} else {
				this.$router.push({
					path: `${this.baseUrl}/${this.currentUrlFilterString}/${this.page}`,
					params: { page: this.page }
				})
			}
		},
		requireIcon
	}
}
</script>
