export const PACKAGE_LEVELS = {
	PREMIUM: 'premium',
	FEATURED: 'featured',
	VERIFIED: 'verified'
}

export const PACKAGE_COLORS = {
	PREMIUM: '#F1B85F',
	FEATURED: '#5C4AE4',
	VERIFIED: '#1FC36A'
}

export const PACKAGE_IDS = {
	PREMIUM_ID: '2',
	FEATURED_ID: '1',
	VERIFIED_ID: '1'
}

export default {
	PACKAGE_LEVELS,
	PACKAGE_COLORS,
	PACKAGE_IDS
}
