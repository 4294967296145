const RECREATIONAL = 'recreational'
const MEDICAL = 'medical'
const BOTH = 'both'

export const destructureNearbyBusiness = (nearbyBusiness) => {
	const {
		name,
		url,
		logo: {
			fileName: image
		},
		ratingOverall,
		reviewCount,
		address: {
			city,
			state
		},
		businessType: type,
		settings: {
			hasMedical,
			hasRecreational
		},
		isOpen,
		delivery
	} = nearbyBusiness

	const getIndication = (medical, recreational) => {
		if (medical && recreational) return BOTH
		if (medical) return MEDICAL
		if (recreational) return RECREATIONAL
	}

	return {
		name,
		url,
		image,
		review_average: ratingOverall,
		review_count: reviewCount,
		city,
		state_name: state?.name || '',
		type,
		indication: getIndication(hasMedical, hasRecreational),
		is_open: isOpen,
		delivery
	}
}
