<template>
	<div
		v-if="multiplePages"
		class="z-10 flex items-center justify-center h-16 -mt-2 space-x-4 bg-white md:h-14"
	>
		<button
			class="flex items-center px-4 py-2 text-sm text-center border border-gray-300 rounded-full shadow-md"
			:class="onFirstPage ? 'invisible' : 'block'"
			@click="goToPreviousPage"
		>
			&lt;
		</button>
		<div class="w-5 text-center">
			{{ currentPage }}
		</div>
		<button
			class="flex items-center px-4 py-2 text-sm text-center border border-gray-300 rounded-full shadow-md"
			:class="onLastPage ? 'invisible' : 'block'"
			@click="goToNextPage"
		>
			&gt;
		</button>
	</div>
</template>

<script async>
export default {
	props: {
		currentPage: {
			type: [ Number, String ],
			required: true
		},
		pageCount: {
			type: Number,
			default: 1
		}
	},
	emits: [ 'page-change' ],
	computed: {
		multiplePages () {
			return this.pageCount > 1
		},
		onLastPage () {
			return Number(this.currentPage) === this.pageCount
		},
		onFirstPage () {
			return Number(this.currentPage) === 1
		}
	},
	methods: {
		goToPreviousPage() {
			this.$emit('page-change', -1)
		},
		goToNextPage() {
			this.$emit('page-change', 1)
		}
	}
}
</script>
