<template>
	<router-link
		:to="dealUrl"
		:disabled="preventRoute"
		:event="!preventRoute ? 'click' : ''"
	>
		<div class="relative block mx-auto border rounded-lg shadow-lg w-[198px] h-[288px] xl:hover:shadow-xl observe">
			<div
				:class="[
					'h-full w-full',
					{ 'opacity-70': isClaimed }
				]"
			>
				<div class="relative h-3/5">
					<WwImage
						:src="deal.image"
						classes="rounded-t-md w-full"
						object-cover
						:alt="deal.title"
						:fallback-image="DEFAULT_WW_GRAY_LOGO"
						:width="375"
						:height="250"
						:image-path="DEAL_IMAGES"
						:fetch-priority="fetchPriority"
					/>
				</div>
				<div class="px-2 h-2/5">
					<div class="flex flex-col content-between h-full">
						<h4 class="pt-2 text-lg text-black h-3/5">
							<span class="font-semibold leading-tight line-clamp-2">
								{{ deal.title }}
							</span>
						</h4>
						<div class="flex justify-between w-full text-sm text-gray-500 uppercase h-1/6">
							<div
								class="truncate"
								tabindex="0"
							>
								{{ dealCategory }}
							</div>
							<div
								v-if="indication"
								tabindex="0"
							>
								{{ indication }}
							</div>
							<div
								class="flex items-center align-center"
								tabindex="0"
							>
								<img
									:src="heartIcon"
									class="w-3 h-3 mr-1 opacity-30"
									alt="Heart"
								>
								<div class="text-gray-500">
									{{ dealClaimCount }}
								</div>
							</div>
						</div>
						<div class="flex h-2/6">
							<div
								v-if="businessName"
								class="self-center justify-center text-sm font-bold text-black truncate hover:text-green-500"
							>
								by {{ businessName }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<span
				v-if="isClaimed"
				class="absolute top-0 w-full text-center text-white bg-green-500 rounded-t-lg shadow-md opacity-95 shadow-green-500/50"
				tabindex="0"
			>
				Claimed!
			</span>
		</div>
	</router-link>
</template>

<script async>
import { mapGetters } from 'vuex'

import { DEAL_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import requireIcon from '@/utils/requireIcon.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		deal: {
			type: Object,
			required: true
		},
		isClaimed: {
			type: Boolean,
			default: false
		},
		prefetchImage: {
			type: Boolean,
			default: false
		},
		preventRoute: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'show-claim-modal' ],
	data() {
		return {
			DEFAULT_WW_GRAY_LOGO,
			DEAL_IMAGES
		}
	},
	computed: {
		...mapGetters([
			'isMobile'
		]),
		fetchPriority() {
			return this.prefetchImage ? 'high' : 'auto'
		},
		indication() {
			return this.deal?.indication
		},
		dealCategory() {
			return this.deal?.category
		},
		heartIcon() {
			return requireIcon('heart-full.svg')
		},
		businessUrl() {
			return this.deal?.url
		},
		businessName() {
			return this.deal?.name
		},
		dealClaimCount() {
			return this.deal?.dealClaimCount
		},
		...mapGetters([ 'isMobile' ]),
		dealId() {
			return this.deal?.id
		},
		dealUrl() {
			return this.businessUrl
				? `${this.businessUrl}/deals/view/${this.dealId}`
				: `deals/view/${this.dealId}`
		}
	},
	methods: {
		useDefaultMainImage(event) {
			event.target.src = this.defaultMainImage
		},
		hasData(data, type) {
			// eslint-disable-next-line valid-typeof
			return typeof (data) === type && data !== ''
		},
		requireIcon
	}
}
</script>
