<template>
	<div class="absolute flex flex-col w-full">
		<div class="flex flex-col justify-center h-auto md:min-h-36">
			<MapControls v-if="!isMobile" />
		</div>
		<template v-if="hasBusinesses">
			<div class="flex justify-center w-full">
				<router-link
					v-if="hasPreviousPage"
					tag="button"
					:to="previousPageRoute"
					class="w-auto px-3 py-1 mx-auto my-4 font-bold text-center text-purple-500 bg-white border border-gray-300 rounded shadow-sm hover:shadow focus:ring focus:ring-green-300 md:mb-4 md:-mt-2"
				>
					Load Previous
				</router-link>
			</div>
			<BusinessMapCard
				v-for="(businessId, index) in businesses.ids"
				:key="`business-${index}`"
				:business="businesses.items[businessId]"
				:lazy-load-image="index > LAZY_LOAD_AFTER"
			/>
		</template>
		<template v-else>
			<SearchEmptyState>
				<template #title>
					{{ noResultsTitle }}
				</template>
				<template #message>
					<div class="w-4/5 m-auto">
						{{ noResultsMessage }}
					</div>
				</template>
			</SearchEmptyState>
		</template>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import BusinessMapCard from '@/components/business/BusinessMapCard.vue'
import MapControls from '@/components/map/MapControls.vue'
import { logError } from '@/utils/error-handling.js'

const LAZY_LOAD_AFTER = 6

export default {
	components: {
		BusinessMapCard,
		SearchEmptyState: () => import('@/components/search/SearchEmptyState.vue'),
		MapControls
	},
	props: {
		businesses: {
			type: Object,
			default: () => ({})
		},
		activeFilters: {
			type: Array,
			default: () => ([])
		},
		isMobile: {
			type: Boolean,
			required: true
		}
	},
	emits: [ 'toggle-map-list' ],
	data() {
		return {
			showFilters: false,
			sortBy: [], // delete if not allowing multiple sort options at once
			activeSortOption: '',
			hasFirstPageResults: true,
			noResultsMessage: 'No results found, try zooming out or changing your search filters.',
			noResultsTitle: 'No results',
			lastElement: '',
			observer: {},
			visible: true,
			LAZY_LOAD_AFTER
		}
	},
	computed: {
		...mapGetters('city', [
			'pageCount'
		]),
		lastPage () {
			return this.routePage >= this.pageCount
		},
		routePage () {
			return this.$route.params.page || 1
		},
		hasBusinesses() {
			return !!this.businesses?.ids?.length
		},
		hasLastElement() {
			return this.isElement(this.lastElement)
		},
		hasPreviousPage() {
			return this.routePage > 1 && !this.hasFirstPageResults
		},
		previousPageRoute() {
			if (!this.hasPreviousPage || this.hasFirstPageResults) return
			const routeParams = Object.assign({}, this.$route.params)
			routeParams.page = this.routePage - 1
			if (routeParams.page <= 1) {
				delete (routeParams.page)
			}
			return { name: 'cityBusinessesTypeSOLO', params: routeParams }
		},
		browserSupportsIO() {
			return window && 'IntersectionObserver' in window
		}
	},
	watch: {
		lastElement(_, oldVal) {
			if (this.hasLastElement && !oldVal) {
				this.startObserver()
			} else {
				this.changeObserver(this.lastElement)
			}
		},
		routePage() {
			if (this.routePage === 1) {
				this.hasFirstPageResults = true
			}
		}
	},
	updated () {
		this.setScrollTargets()
	},
	mounted() {
		if (this.routePage > 1) {
			this.hasFirstPageResults = false
		}
		this.setScrollTargets()
	},
	methods: {
		setScrollTargets() {
			if (this.hasBusinesses) {
				const scrollTargets = document.querySelectorAll('div.observe')
				this.lastElement = scrollTargets[scrollTargets.length - 5]
			}
		},
		handleScroll: async function () {
			if (parseInt(this.routePage) < this.pageCount) {
				try {
					const routeParams = Object.assign({}, this.$route.params)
					if (isNaN(routeParams.page) || typeof routeParams.page === 'undefined') {
						this.$route.params.page = 1
						routeParams.page = 2
					} else {
						routeParams.page++
					}
					this.$router.replace({
						name: this.$route.name,
						params: routeParams
					})
				} catch (e) {
					logError(e)
				}
			}
		},
		isElement(obj) {
			return obj instanceof HTMLElement
		},
		observerCallback(entries, observer) {
			if (entries[0].isIntersecting && this.browserSupportsIO) {
				observer.unobserve(entries[0].target)
				this.handleScroll()
			}
		},
		startObserver() {
			if (this.hasLastElement && this.browserSupportsIO) {
				this.observer = new IntersectionObserver(this.observerCallback)
				this.observer.observe(this.lastElement)
			}
		},
		changeObserver(newElement) {
			if (this.hasLastElement && this.browserSupportsIO) {
				this.observer.observe(newElement)
			}
		}
	}
}
</script>
