<template>
	<div class="flex flex-1">
		<div
			v-if="shouldShowList"
			class="relative flex flex-col w-full overflow-y-auto md:w-[432px]"
		>
			<BusinessList
				v-if="showBusinessProfile"
				:businesses="businesses"
				:active-filters="activeFilters"
				:is-mobile="isMobile"
			/>
			<div
				v-else-if="!isMobile"
				class="absolute w-full px-4 py-2 mx-auto text-left"
			>
				<nav
					class="py-2"
					aria-label="breadcrumb"
				>
					<div
						class="flex items-center text-black cursor-pointer"
						@click="clearActiveBusiness"
						@keydown.enter="clearActiveBusiness"
					>
						<img
							:src="backIcon"
							alt="Back"
							width="16"
							height="16"
						>
						<div
							class="mx-2"
							tabindex="0"
						>
							Back to List
						</div>
					</div>
				</nav>
				<BusinessDetails
					:business="businessProfileData || {}"
					is-map
					class="my-2"
				/>
				<div class="flex w-full py-6">
					<SocialShare class="ml-auto">
						<template #default="{ share }">
							<button
								type="button"
								class="h-10 px-3 bg-white border border-gray-300 rounded-lg shadow-sm"
							>
								<span
									class="flex items-center w-full"
									@click="share"
								>
									<img
										src="@/assets/icons/share-alternate.svg"
										alt="social share"
										height="25"
										width="25"
										class="mr-2"
									>
									<span class="w-full font-bold text-black uppercase">
										Share
									</span>
								</span>
							</button>
						</template>
					</SocialShare>
				</div>
				<div
					v-if="hasMenu"
					class="block w-full"
				>
					<router-link
						tag="button"
						:to="{ path: activeBusinessPath }"
						class="flex items-center justify-center w-full h-12 font-bold text-center uppercase bg-white border border-gray-300 border-solid rounded-lg shadow-sm"
					>
						<img
							:src="cartIcon"
							class="mr-2"
							width="25"
							height="25"
							alt="Cart Icon"
						>
						View Online Menu
					</router-link>
				</div>
				<div
					v-else
					class="w-full"
				>
					<div class="block w-full">
						<router-link
							tag="button"
							:to="{ path: activeBusinessPath }"
							class="flex items-center justify-center w-full h-12 font-bold text-center uppercase bg-white border border-gray-300 border-solid rounded-lg shadow-sm"
						>
							Go To Business Page
						</router-link>
					</div>
					<MenuEmptyState
						:business-type-url="typeUrl"
						:nearby-businesses="nearbyBusinesses"
						:is-map-page="true"
						class="mb-2 md:mt-4"
					/>
				</div>
				<div
					v-if="hasDeals && hasMenu"
					class="pb-3"
				>
					<div
						class="w-full pt-2 pb-1 font-bold text-left uppercase"
						tabindex="0"
					>
						Active Deals
					</div>
					<div class="grid grid-cols-2 gap-3">
						<router-link
							v-for="(deal, index) in deals"
							:key="index"
							:to="`${businessProfileData.url}/deals#${deal.id}`"
						>
							<DealCard
								:key="index"
								:deal="deal"
							/>
						</router-link>
					</div>
				</div>
				<div
					v-if="!hasDeals && hasMenu"
					class="w-full h-8 pt-2"
				>
					<div
						v-if="hasAboutSectionData"
						class="py-2"
					>
						<h3
							class="flex items-center w-full pb-2 text-xl font-bold"
							tabindex="0"
						>
							<img
								src="@/assets/icons/about.svg"
								class="w-6 h-6 mr-3"
								alt="About Icon"
							>
							About
						</h3>
						<div
							class="w-full pb-4"
							tabindex="0"
							v-html="businessProfileData.about"
						/>
					</div>

					<div
						v-if="hasAmenities"
						class="pb-4"
						tabindex="0"
					>
						<h3 class="flex items-center w-full pb-2 text-xl font-bold">
							<img
								src="@/assets/icons/amenities.svg"
								class="w-6 h-6 mr-3"
								alt="Amenities Icon"
							>
							Amenities
						</h3>
						<ul class="w-full ml-3">
							<li
								v-for="(feature, index) in activeBusinessData.business.features"
								:key="index"
								class="text-left list-disc"
							>
								{{ activeBusinessData.business.features[index].name }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<client-only>
			<Map
				v-if="hasShownMap"
				v-show="shouldShowMap"
				:is-loading="isLoading"
				:active-business="activeBusiness"
				:businesses="mapBusinesses"
				:active-filters="activeFilters"
				@fetch-businesses="fetchBusinesses"
			/>
		</client-only>
	</div>
</template>

<script async>
import ClientOnly from 'vue-client-only'
import { mapGetters, mapMutations, mapState } from 'vuex'

import { destructureNearbyBusiness } from '@/api/helpers/destructureNearbyBusiness.js'
import { fetchBusinesses } from '@/api/map/index.js'
import MenuEmptyState from '@/components/business/BusinessEmptyMenu.vue'
import { LIST, MAP } from '@/constants/map/map-modes.js'
import { GetBusinessDetails } from '@/gql/business/queries/components.gql'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'
import { deleteResizedMarker } from '@/utils/map.js'
import requireIcon from '@/utils/requireIcon.js'

import BusinessList from './components/BusinessList.vue'

export default {
	name: 'CityBusinesses',
	components: {
		BusinessList,
		Map: () => import('@/views/city/components/Map.vue'),
		BusinessDetails: () => import('@/components/business/header/BusinessDetails.vue'),
		DealCard: () => import('@/components/deal/DealCard.vue'),
		SocialShare: () => import('@/components/multiUse/SocialShare.vue'),
		MenuEmptyState,
		ClientOnly
	},
	data() {
		return {
			isLoading: false,
			tempBusinesses: {},
			activeBusiness: 0,
			activeBusinessId: 0,
			activeBusinessSeoUrl: '',
			activeBusinessPath: '',
			activeBusinessData: {},
			showFilters: false,
			activeSortOption: '',
			MAP,
			LIST,
			hasShownMap: false
		}
	},
	apollo: {
		businessProfileData: {
			query: GetBusinessDetails,
			update(data) {
				if (data.listing) {
					return {
						...data.listing
					}
				}
			},
			variables() {
				return {
					seoUrl: this.activeBusinessSeoUrl,
					isMap: true,
					showNearbyListings: !this.hasMenu
				}
			},
			error(error) {
				error.graphQLErrors.forEach((errorItem) => {
					componentLevelGQLErrors(errorItem)
				})
			},
			skip() {
				return !this.activeBusinessSeoUrl.length
			}
		}
	},
	computed: {
		...mapState([
			'meta'
		]),
		...mapGetters('city', [
			'businesses',
			'pageCount'
		]),
		...mapGetters('map', [ 'searchMode', 'selectedBusiness' ]),
		...mapGetters([ 'isMobile' ]),
		cartIcon() {
			return requireIcon('cart2.svg')
		},
		hasAmenities() {
			return !!this.activeBusinessData?.business?.features?.length
		},
		backIcon() {
			return requireIcon('back.svg')
		},
		activeFilters () {
			return (!!this.$route.fullPath && this.$route.fullPath.substring(1).split(/[+/]/)) || []
		},
		filterSet () {
			return this.$store.state.city.filterSet || {}
		},
		shouldShowList() {
			return this.isMobile ? this.searchMode === LIST : true
		},
		shouldShowMap() {
			return this.isMobile ? this.searchMode === MAP : true
		},
		hasTempBusinesses() {
			return !!this.tempBusinesses && !!Object.keys(this.tempBusinesses).length
		},
		hasDeals() {
			return !!this.businessProfileData?.deals?.length
		},
		deals() {
			return this.businessProfileData?.deals || []
		},
		hasMenu() {
			const categories = this.businessProfileData?.categories || []
			for (const category of categories) {
				if (category?.itemsCount) return true
			}
			return false
		},
		hasAboutSectionData() {
			return this.businessProfileData?.about?.length > 0
		},
		showBusinessProfile() {
			return this.activeBusinessId == 0 || this.isMobile
		},
		mapBusinesses() {
			if (this.tempBusinesses === {} || typeof this.tempBusinesses !== 'object') return {}
			const tempBusinessesAsArray = Object.entries(this.tempBusinesses)
			const filteredTempBusinesses = tempBusinessesAsArray.filter(([ _, business ]) => {
				return !!business.claimed
			})
			if (filteredTempBusinesses.length > 0 && filteredTempBusinesses.length > 10) {
				return Object.fromEntries(filteredTempBusinesses)
			}
			return this.tempBusinesses
		},
		nearbyBusinesses() {
			return this.businessProfileData?.nearbyListings?.map(listing =>
				destructureNearbyBusiness(listing)).slice(0, 3) || []
		},
		typeUrl() {
			return this.menuData?.typeUrl || ''
		}
	},
	watch: {
		selectedBusiness(business, oldBusiness) {
			if (business && business !== oldBusiness) {
				this.setActiveBusiness()
			} else {
				this.clearActiveBusiness()
			}
		},
		shouldShowMap: {
			handler(newVal) {
				if (newVal === true) {
					this.hasShownMap = true
				}
			},
			immediate: true
		}
	},
	methods: {
		...mapMutations('map', [ 'setSelectedBusiness' ]),
		async fetchBusinesses(bounds) {
			this.isLoading = true
			this.tempBusinesses = await fetchBusinesses({ bounds, route: this.$route })
			this.isLoading = false
		},
		async setActiveBusiness() { // #TODO: Refactor this to use GQL to get business data
			this.activeBusinessId = parseInt(this.selectedBusiness?.listing_id)
			this.activeBusinessSeoUrl = this.selectedBusiness?.seo_url
			this.activeBusinessPath = this.selectedBusiness?.url
		},
		clearActiveBusiness() {
			this.activeBusinessData = {}
			this.activeBusinessId = 0
			this.activeBusinessSeoUrl = ''
			this.activeBusinessPath = ''
			this.setSelectedBusiness(null)
			deleteResizedMarker()
		},
		handleMapListChange(value) {
			this.searchMode = value
		}

	}
}
</script>
