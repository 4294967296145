<template>
	<div class="w-80 md:w-full">
		<div class="m-auto">
			<div class="w-full pt-6 mb-4 text-center md:pt-10">
				<div class="mb-3 text-xl font-bold">
					Menu Not Available
				</div>
				<div class="max-w-sm m-auto text-base text-gray-500">
					This business hasn’t made its menu <br>available on Wheres Weed yet.
				</div>
			</div>

			<div class="w-64 pb-2 m-auto min-h-12">
				<router-link
					v-if="showViewMoreButton"
					tag="button"
					class="w-full h-12 font-semibold uppercase bg-white border-2 border-gray-300 rounded-md shadow-md"
					:to="{ path: businessTypeUrl }"
				>
					<h2 class="p-2 m-auto text-sm">
						View Other Businesses
					</h2>
				</router-link>
			</div>

			<div
				class="max-w-md pt-10 m-auto min-w-xs min-h-56"
				:class="cardDetailWidth"
			>
				<BusinessNearbyCarousel
					v-if="hasNearbyBusinesses"
					:items="nearbyBusinesses"
					:item-count="nearbyBusinesses.length"
					:is-map-page="isMapPage"
				/>
			</div>
		</div>
	</div>
</template>

<script async>
export default {
	components: {
		BusinessNearbyCarousel: () => import('@/components/business/BusinessNearbyCarousel.vue')
	},
	props: {
		businessTypeUrl: {
			type: String,
			required: true
		},
		nearbyBusinesses: {
			type: Array,
			required: true
		},
		showViewMoreButton: {
			type: Boolean,
			default: false
		},
		isMapPage: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		autoPlaying: true,
		internalAutoPlaying: true
	}),
	computed: {
		mediaMatch() {
			return this.$store.state.mediaMatch
		},
		cardDetailWidth() {
			return this.mediaMatch !== 'sm' && this.mediaMatch !== 'xs' ? 'full-card-width' : ''
		},
		hasNearbyBusinesses() {
			return this.nearbyBusinesses?.length
		}
	}
}
</script>
